import ArtistView from './views/ArtistView/ArtistView.vue';
import GalleryView from './views/GalleryView/GalleryView.vue';

const routes = [{
  path: '/', redirect: '/artists'
}, {
  path: '/artists', component: ArtistView
}, {
  path: '/gallery', component: GalleryView
}]

export default routes;

<template>
  <transition name="fade">
    <div class="artist-info-container" v-if="artist">
      <div class="back" title="close" @click="() => setSlectedArtist('')">
        &times;
      </div>
      <div class="logo">
        <img class="logo-img" :src="image" alt="" />
      </div>
      <div class="artist-description" :class="progress >= 1 ? 'show' : 'hide'">
        <h3>ABOUT</h3>
        {{ artist ? artist.artistDescription : "" }}
      </div>
      <div class="artist-interview" :class="progress >= 2 ? 'show' : 'hide'">
        <h3>INTERVIEW</h3>
        <iframe
          title="vimeo-player"
          class="interview-frame"
          :src="artist.interviewLink"
        />
      </div>
      <div class="links" :class="progress >= 3 ? 'show' : 'hide'">
        <h3>MUSIC</h3>
        <a
          v-for="(link, name) of artist.links"
          :key="selectedArtist + name"
          class="link"
          :href="link"
          target="_blank"
          rel="noopener noreferrer"
          >{{ name }}</a
        >
      </div>
    </div>
  </transition>
</template>

<script>
import artists from "@/constants/artists";

export default {
  name: "ArtistInfo",
  props: {
    selectedArtist: {
      type: String,
    },
    progress: {
      type: Number,
    },
    setSlectedArtist: {
      type: Function,
      required: true,
    }
  },
  computed: {
    artist() {
      return artists[this.selectedArtist];
    },
    image() {
      try {
        return require(`@/assets/artists/${this.selectedArtist}.png`);
      } catch {
        return "";
      }
    },
  },
  data() {
    return {};
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "~@/constants/_variables.scss";

.artist-info-container {
  position: absolute;
  right: 40px;
  top: 0;
  width: 350px;
  overflow-y: auto;
  height: 100%;
  padding: 20px 0 10px;
  box-sizing: border-box;

  @media screen and (max-width: $small-media-width + px) {
    width: 310px;
  }
}

h3 {
  margin: 10px 0;
}

.logo-img {
  display: block;
  max-width: 100%;
}

.artist-description {
  white-space: break-spaces;
  font-size: 14px;
  font-weight: bold;
  line-height: 1;
}

.links {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.show {
  transition: 0.3s opacity;

  opacity: 1;
}

.hide {
  opacity: 0;
}

.link {
  color: #b3b3b3;
  text-decoration: none;
  transition: color 0.3s;
  margin-left: 10px;

  &:hover {
    color: #fff;
  }
}

.interview-frame {
  width: 100%;
  // height: 200px;
  border: none;
  background: #b3b3b3;
}

.back {
  position: fixed;
  top: -5px;
  right: 60px;
  font-size: 40px;
  color: #fff;
  font-family: sans-serif;
  font-weight: 100;
  text-decoration: none;
  opacity: 0.3;
  transition: 0.3s;
  z-index: 9;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }
}
</style>

import Vue from "vue";
import VueModal from "@kouts/vue-modal";
import "@kouts/vue-modal/dist/vue-modal.css";
import "@google/model-viewer";
import VueRouter from "vue-router";
import "masonry-layout/masonry";

import App from "./App.vue";
import "./main.css";
import routes from "./routes";

import { VueMasonryPlugin } from "vue-masonry";

Vue.use(VueMasonryPlugin);

Vue.use(VueRouter);
Vue.config.productionTip = false;

Vue.component("Modal", VueModal);

const router = new VueRouter({
  routes,
});

Vue.mixin({
  data: function() {
    return {
      publicPath: process.env.BASE_URL,
    };
  },
});

new Vue({
  render: (h) => h(App),
  router,
}).$mount("#app");

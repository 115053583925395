export default {
  ali: {
    artistName: "Ali-970",
    angle: 280,
    artistDescription:
      "00970, an electronic music project from Ramallah, transforms the sounds around him into industrial textures, aiming to capture and portray the disorder of the world he comes from. By fusing Heavy Kicks, Ambient, and Experimental elements in his music, a uniquely dark atmosphere, both internal and external, is created.",
    links: {
      interview:
        "https://www.31mag.nl/palestines-electronic-underground-in-amsterdam-an-interview-with-ramallahs-970/",
      soundcloud: "https://soundcloud.com/00970",
      youtube: "https://www.youtube.com/channel/UCncqo6vOGiYxXQrNy9LxsCQ",
      bandcamp: "https://00970.bandcamp.com",
      instagram: "https://www.instagram.com/____________00970_____________/",
    },
    city: "ramallah",
  },
  bltnm: {
    artistName: "BLTNM",
    angle: 290,
    artistDescription:
      "BLTNM is an independent digital record label based in Ramallah, Palestine. They support and promote artists across the Arab World by releasing music, organizing tours, and securing press coverage for our artists.They want to become the go to place for arab artists to creat and release their music , without succumbing to the creative compromises often demanded by big labels.",
    links: {
      soundcloud: "https://www.soundcloud.com/bltnm",
      website: "https://bltnm.com/about",
      youtube: "https://www.youtube.com/channel/UC1SawInmaC5j-QOPyOCN_Bg",
    },
    city: "ramallah",
  },
  dakn: {
    artistName: "Dakn",
    angle: 299,
    artistDescription:
      "An MC, producer & DJ based in Ramallah, Palestine. As part of the Saleb 1 crew and as a solo artist, he has released many singles and collaborated with multiple musicians and MC’s. His lyrical content is controversial, conscious, and abstract. While his production evolves with every release, a recurring theme is his bass heavy elements and focus on ambient sounds.",
    links: {
      youtube: "https://www.youtube.com/channel/UCdZjFNGLqFHVKPSg33HYxoQ",
      soundcloud: "https://soundcloud.com/dakn-1",
      instagram: "https://www.instagram.com/dakn791/",
    },
    city: "ramallah",
  },
  dina: {
    artistName: "Dina Amro",
    angle: 306.7,
    artistDescription:
      "Dina Amro known as “Bint Mbareh “ .She researches in ethnomusicology as a culture bearer .She has made songs with with Istiwnat, the Band, Kareem Samara/ Al-Hazeemeh, as well as performances emerging from my field research into rain summoning music. She has completed her undergraduate at Maastricht University and my Master of Art in Ethnomusicology at Goldsmiths University",
    links: {
      archive: "https://www.khidrcollective.co.uk/dina-amro",
    },
    city: "ramallah",
    interviewLink: "https://player.vimeo.com/video/528774533",
  },
  dar: {
    artistName: "Dar",
    angle: 319.5,
    artistDescription: `DJ Dar comes from the word 'house,' a small space where DAR started playing most of his gigs back 2014.Dar is also part of the UNION collective which is an underground DJ collective based in Ramallah,Palestine .Its purpose was to pand the underground scene in Palestine, to give a chance for artists to develop .
Dar started as a bedroom DJ, mainly playing at house parties. The restrictions of the Palestinian scene made it hard for him to get gigs and play more parties, which is why he got on board with Sama’ when she suggested to start a union to help the scene grow. That’s how Union Naqaba was born! Dar is also a social entrepreneur, working on encouraging and helping Palestinian entrepreneurs grow their business and find the resources they need via Build Palestine.`,
    links: {
      instagram: "https://www.instagram.com/dar_970/",
      soundcloud: "https://soundcloud.com/dar970",
    },
    city: "ramallah",
  },
  nather: {
    artistName: "Al Nather",
    angle: 327.3,
    artistDescription: `Al Nather has been making music for almost a decade now. Growing up, he was a part of a local collective known as Saleb Wahad (-1), and through it he learned to hone the Arabic-infused style that was later to become a part of his unique sound.
      Today’s Al Nather is a well-established rapper and producer, who collaborated with some of the most popular artists in the region and performed alongside the likes of Jamie xx, Kutmah, and Sinistarr. Al Nather’s work was featured heavily in Boiler Room’s audiovisual project Palestine Underground. In recent years, Al Nather has become Shabjdeed’s executive and producer. The duo put out an impressive repertoire of hits and have dropped the critically acclaimed debut album, Sindibad, in 2019.`,
    links: {
      instagram: "https://www.instagram.com/al.nather/",
      soundcloud: "https://soundcloud.com/bltnm",
    },
    city: "ramallah",
  },
  falyakon: {
    artistName: "Falyakon",
    angle: 336.4,
    artistDescription:
      "dj and a selector from Palestine, currently living in Berlin. Emerging in the Palestinian underground scene Falyakon has been playing shows in Palestine alongside Muqata’a showcasing her dark sounds around the country.",
    links: {
      instagram: "https://www.instagram.com/falyakon111/",
      soundcloud: "https://soundcloud.com/falyakon",
      "Mix Cloud":
        "hhttps://www.mixcloud.com/movement_radio/guest-mix-by-falyakon-w-muqataa-01062021/",
    },
    city: "ramallah",
  },
  haykal: {
    artistName: "Haykal",
    angle: 347,
    artistDescription:
      "Haykal, one of the pioneers of the Underground scene in Palestine. His unique flow and grime style brings a different taste to the hip hop scene in Palestine. His project EP “Kasser El Ard” features Ramalah’s anthem “Sot Ramallah”.Haykal is a rapper that is also part of -1 .He is based in Ramallah.",
    links: {
      instagram: "https://www.instagram.com/haykalhon/",
      soundcloud: "https://soundcloud.com/haykalonol",
      spotify:
        "https://open.spotify.com/artist/77yI3yz3pB3zU9MV4C7XHV?si=Qe2fJoxuSmu7wQ0ZZLrmxg&dl_branch=1",
      youtube: "https://www.youtube.com/channel/UCj8Ivpuc-ORoGmt74Vm4m2Q",
    },
    city: "ramallah",
  },
  julmud: {
    artistName: "Julmud",
    angle: 9.8,
    artistDescription:
      "A producer, percussionist, keyboardist, and vocalist/mc based in Ramallah-Palestine, that creates, composes, samples and combines sounds from across the globe to make a new non-generic form of music. He has produced work for different MC’s such as Haykal, Shua, Muqata’a, Dakn & Makimakkuk among others who are also a part of crew (Saleb Wahad -1). And is currently in the process of producing a variety of solo and collaborative works.",
    links: {
      soundcloud: "https://soundcloud.com/julmud-1",
      instagram: "https://www.instagram.com/julmud_1/",
    },
    city: "ramallah",
  },
  makimukuk: {
    artistName: "Makimukuk",
    angle: 20,
    artistDescription:
      "Makimakkuk (“makkuk” meaning “space shuttle” in Arabic), musician Majdal Nijim is a Ramallah-based producer, MC, vocalist, and DJ, who has released several tracks through the digital record label, BLTNM. Makimakkuk is a relatively new figure in the Arab hip-hop landscape. She performs and produces with a range of collaborators, from the Ramallah rap scene to the underground corners of Scandinavian dreampop. Last November she was briefly featured in a documentary short produced by Boiler Room in collaboration with Maazef music magazine titled Palestine Underground, which chronicled “the resilience of a burgeoning music scene” by means of beat master Muqata’a and techno DJ and producer Sama’, among others. During that time she also appeared in Boiler Room’s performance segment with Mukta-feen (Ahmed Zagmouri) in a show packed with the prowess of pure, uninhibited hip-hop.",
    links: {
      soundcloud: "https://soundcloud.com/makimakkuk",
      instagram: "https://www.instagram.com/makimakkuk/",
      spotify:
        "https://open.spotify.com/artist/1foIEikUEDSL8sCj5BLFcv?si=MwPEI6tDR1qP_usRAD8WDg&dl_branch=1",
      youtube: "https://www.youtube.com/c/Makimakkuk",
      interview:
        "https://www.vice.com/en_us/article/vbadyy/makimakkuk-palestine-boiler-room-music-interview",
    },
    city: "ramallah",
  },
  muqataa: {
    artistName: "Muqata’a",
    angle: 29,
    artistDescription:
      "Muqata'a is an electronic musician and MC based in Ramallah, Palestine. Creating sounds using sampled material, field recordings, and electronic devices, the results range between harsh beats, abstract tones, and glitch. He co-founded the Ramallah Underground Collective [2002-2009]. Muqata'a is also co-founder and member of the sound and image performance group Tashweesh alongside artists Basel Abbas and Ruanne Abou-Rahme. He has released several solo albums including; Inkanakuntu (2018) on Discrepant, Dubt Al-Ghubar (2017) on Third Type Tapes, La Lisana Lah (2017), and Hayawan Nateq (2013). Muqata’a has composed several film scores, as well as scores for dance performances, and is now working on different collaborative and solo projects.",
    links: {
      soundcloud: "https://soundcloud.com/muqataa",
      instagram: "https://www.instagram.com/muqataa/",
      spotify:
        "https://open.spotify.com/artist/6IuLKDlw1ocgiIId0Guhgl?si=EZ33bNLfRmWVWCBohhi2MQ&dl_branch=1",
      youtube: "https://www.youtube.com/user/boikutt",
    },
    city: "ramallah",
  },
  oddz: {
    artistName: "Oddz",
    angle: 36.5,
    artistDescription:
      "One of the first who started the Underground Music Scene in Ramallah. DJ and event planner since 2006, and a part of Radio Nard team and a founder of عAlam Talit, a new culture & art collective based in Ramallah. Played in Amman/Beirut/Haifa/Jaffa. Along with big names such as Takaaki Itoh from WOLS Records - SInistarr from Metalheadz- Concret from Toy Tonics Records -Richard Bredicz Sigma Records- EL-B Ghost Recordings -Sama -SHUSMO Academics -Currently living in Ramallah now and aiming to create a Palestinian underground scene through music , arts and educational content and activities .",
    links: {
      soundcloud: "https://soundcloud.com/odai-masri",
      instagram: "https://www.instagram.com/3oddz/",
    },
    city: "ramallah",
  },
  sama: {
    artistName: "Sama",
    angle: 49,
    city: "ramallah",
    artistDescription: `Sama Saad Abdulhadi (Arabic: سماء سعد عبدالهادي) is a Palestinian audio engineer, sound designer, and music producer, as well as Palestine's first techno DJ. Formerly known as SkyWalker, under which she released two albums, she now goes by SAMA', her first name and Arabic for "sky". Abdulhadi was born in Jordan and raised in Ramallah, where she was active in that city's early-2000s hip hop scene as a rapper, breakdancer, and record scratcher, and began mixing her own music in 2006. Abdulhadi was later on introduced to electronic music after hearing albums from Tiësto and iiO and attending a Satoshi Tomiie concert in Beirut.
Abdulhadi earned a Diploma in Audio Engineering from the SAE Institute in Jordan in 2010, followed by a Bachelor of Science in Audio Engineering and Music Production from the SAE Institute in London in 2012.
In 2013 Abdulhadi relocated to Cairo, where she lived and worked for four years as an audio engineer, sound designer, and music producer for Egyptian and Arab films. Abdulhadi also founded a publishing agency, Awyav, to represent independent Arab artists. In the meantime Abdulhadi continued to tour internationally and released two albums under the moniker SkyWalker.
In 2017 Abdulhadi was selected as a Laureate by the French Institute and spent a six-month residency at the Cité internationale des arts in Paris.`,
    links: {
      soundcloud: "https://soundcloud.com/sama_saad",
      spotify:
        "https://open.spotify.com/artist/52qyXsgOeHZZxHRRxyO8xm?si=jbWku9JzT6Sc16urX7gK9g",
      instagram: "https://www.instagram.com/sama__dj/",
    },
  },
  sarouna: {
    artistName: "Sarouna",
    angle: 57,
    artistDescription:
      "Sarouna is a Palestinian music producer, audio engineer, musician &DJ.She draws on many musical influences, having grown up playing the Qanua and experimenting with music & technology. SHe is a Berklee College of Music graduate, having studied under SImon Shaheen and world renowned producers &engineers. She’s currently working on establishing an independent Palestinian record label that works on providing an infrastructure for sustainable music industry’s well as continuously working on her own musical projects and collaborations with other local and regional artists.",
    links: {
      soundcloud: "https://soundcloud.com/sarounam",
      instagram: "https://www.instagram.com/sarounamusic/",
    },
    city: "bethlehem",
  },
  alnard: {
    artistName: "AL-NARD",
    angle: 76,
    artistDescription:
      "NRD نرد (Dices in arabic) - Bethlehem based Frog, Digger, Selector, Radio operator, organizer, MC, Producer & Electrical Engineer. childhood background in Classic oriental music, Learned Qanoun since age 6 in the national Conservatory of Music - Palestine. The Electronic Commit came later in high school, through Classic Dubstep, it formed a new dimension to imagination.",
    links: {
      soundcloud: "https://soundcloud.com/radio-nard",
      instagram: "https://www.instagram.com/radio_nard/",
    },
    city: "bethlehem",
  },
  ghandi: {
    artistName: "Ghandi",
    angle: 99,
    artistDescription:
      "Nasser goes by the name Ghandi, he was raised between Bethlehem and Jordan but now is based in Bethlehem. He is a DJ/Selector known for his Varesttile clubbing sound , jumping between the raw minimal to a rolling bass vibe , he knows how to hold the crown when he’s on decks with his smooth tricky mixing skills . Nasser is Part of Radio Nard team ,he is also a resident DJ , and a big part of the growing movement of the underground scene in Bethlehem, Palestine .",
    links: {
      instagram: "https://www.instagram.com/gh_an_dii/",
      soundcloud:
        "https://soundcloud.com/nasser-ghandi?ref=clipboard&p=a&c=1&utm_campaign=social_sharing&utm_medium=text&utm_source=clipboard",
    },
    city: "bethlehem",
  },
  harout: {
    artistName: "Harout",
    angle: 109,
    artistDescription:
      "HAROUT S, known for his dark grooves and track selection, Harout has been playing various venues around Palestine making himself an important part of the underground scene. His “Bassment Mix 004” will give you a taste of his style",
    links: {
      instagram: "https://www.instagram.com/harout_iz_here/",
      soundcloud: "https://soundcloud.com/harout-sultan-1",
    },
    city: "bethlehem",
  },
  kalli: {
    artistName: "Kalli",
    angle: 118.5,
    artistDescription:
      "HAROUT S, known for his dark grooves and track selection, Harout has been playing various venues around Palestine making himself an important part of the underground scene. His “Bassment Mix 004” will give you a taste of his style",
    links: {
      instagram: "https://www.instagram.com/official_kalli/",
      soundcloud: "https://soundcloud.com/kallisounds/",
    },
    city: "bethlehem",
  },
  youset: {
    artistName: "Youset",
    angle: 126.1,
    artistDescription: `Yousef Anastas one of the founders of Local industries ,which was founded in 2011 by Elias and Yousef Anastas, two Palestinian architects .Yousef was also one of the founders of Radio Alhara along with Elias Anastas, Yazan Khalili, Mothanna Hussein, Saeed Abu Jaber. An online Radio that was established during the Pandemic ,Radio Alhara emerges in a time of isolation and boredom, a time where the future of the world as we know it remains unknown.
The project is inspired by Radio Alhai Beirut and Radio Alhuma Tunis, which were in turn inspired by Radio Quartiere Milano.This is a communal radio station/ program / project, it begins in Bethlehem and Ramallah, and aims to extend to other places in Palestine and throughout the world, an open platform for listening, adding, discussing, and chatting.`,
    links: {
      instagram: "https://www.instagram.com/yousefanastas/",
      "Radio AlHara": "https://yamakan.place/palestine/",
    },
    city: "bethlehem",
  },
  souty: {
    artistName: "Souty",
    angle: 139,
    artistDescription: "",
    links: {
      instagram: "https://www.instagram.com/soutysound/",
      soundcloud: "https://soundcloud.com/el-souty",
    },
    city: "bethlehem",
  },
  mirna: {
    artistName: "Mirna Bamye",
    angle: 147,
    artistDescription: `Mirna Bamieh is an artist from Jerusalem/Palestine with a BA in Psychology from Birzeit University in Ramallah and an MFA in Fine Arts from Bezalel Academy for Arts and Design in Jerusalem. She participated in the Ashkal Alwan Home Workspace program in Beirut as a 2013/14 fellow, and obtained a Diploma in Professional Cooking two years ago—which led her to use storytelling and food as mediums for creating socially engaged projects.
Through her art practice she aspires to create artworks where food/eating/sharing create a fresh, innovative way for people to experience themselves and their surroundings. Projects include: Maskan Apartment Project, Potato Talks Project, and—for the past 3 years—a full focus on Palestine Hosting Society.`,
    links: {
      instagram: "https://www.instagram.com/palestine_hosting_society/",
      website: "https://mirnabamieh.info/about-the-artist",
    },
    city: "jerusalem",
  },
  bashar: {
    artistName: "Bashar Murad",
    angle: 156,
    artistDescription: `is a Palestinian singer, songwriter, and social activist based in East Jerusalem. His music addresses societal norms, the Israeli Occupation, and LGBT+ rights in the Middle East. He is most commonly known for his collaboration with Icelandic techno-punk band Hatari on the song "Klefi / Samed (صامد)", which was shortly released after Hatari raised the Palestinian flag at the grand finale of Eurovision 2019 in Tel Aviv, Israel.His mother's name is Fadia Daibes and his father is Said Murad, the founder of the Palestinian musical group, Sabreen, the first alternative Palestinian musical group.[5] The band was founded in 1980 and Murad was born during the height of their career.[1] This is one reason why for him music was always part of his identity and a means to escape from reality.`,
    links: {
      instagram: "https://www.instagram.com/basharmuradofficial/",
      spotify:
        "https://open.spotify.com/artist/2egtqAaRFGmPkiMpmSHTql?si=moJS1ucsQlimqoawFzzUtw&dl_branch=1",
      youtube: "https://www.youtube.com/channel/UC9DLFfJGeFf85flt2hy3kUQ",
    },
    city: "jerusalem",
  },
  issa: {
    artistName: "Bi7ki Jazz",
    angle: 166.1,
    artistDescription: "Issa is a Rapper Based in Jerusalem.",
    links: {
      soundcloud: "https://soundcloud.com/abul3ees",
      instagram: "https://www.instagram.com/bi7kijazz/",
      youtube: "https://www.youtube.com/channel/UC3JN0-gzcijK2m8G5xvADVg",
    },
    city: "jerusalem",
  },
  yasmin: {
    artistName: "Yasmin Eve",
    angle: 180,
    artistDescription:
      "Yasmine will transport you to her progressive universe, her music is the result of these various influences, which she shares in clubs and festivals, in Haifa, Ramallah, or even in Germany at the Moyn Moyn festival, but also recently in Belgium, in February at the United frequencies festival. Born in the city of Nazareth, she grew up there. Before spending more than ten years in Florida, rocked by oriental music, she discovered Latin and Hispanic music there. Her music is thus inspired by these two worlds, and this richness brings a unique touch to the musical journey of his sets. She is currently based in Haifa .",
    links: {
      instagram: "https://www.instagram.com/yasminexeve/",
      soundcloud: "https://soundcloud.com/yasmine-eve",
    },
    city: "haifa",
  },
  zenobia: {
    artistName: "Zenobia",
    angle: 189,
    artistDescription:
      "Combining Arabic pop melodies, Palestinian/Syrian dabke rhythms, synths & powerful beats with occasional dubby flavours, Zenobia’s hypnotic live performances have made a big impression over a short period of time:  the band started performing in 2018, and has already appeared in the Middle East, Paris, Berlin, London, Amsterdam, South Korea and Brazil. Zenobia are at the forefront of the new, vibrant Palestinian electronic music movement, originating in parallel between Haifa and Ramallah, scattered yet gradually more unified, and now extending across the borders to Amman, Beirut and the diaspora. The band consists of Nasser Halahlih, a pioneer who has been at the core of the scene since its early days, and fleet-fingered keyboardist Isam Elias.    ",
    links: {
      instagram: "https://www.instagram.com/zenobiatunes/?hl=en",
      soundcloud: "https://soundcloud.com/zenobiatunes",
      spotify:
        "https://open.spotify.com/artist/1111ivBPyEwa1mdOB2SA0X?si=wzVPDkBtTSuLlkOuvaajsg&utm_source=copy-link&nd=1",
      youtube: "https://www.youtube.com/channel/UCYpYWAZrFNIPy7g22t8vVjA",
    },
    city: "haifa",
  },
  sinan: {
    artistName: "Sinan",
    angle: 199.5,
    artistDescription:
      "Sinan Abdel Moati, unlike many of his kind, his eclectic taste in music makes his works diverse, unique, mystical and otherworldly. His journey began as he was 9 years old, taking a guitar and strumming his first string. He spent most of his youth at the Rubin conservatory of music in Haifa, acquiring professional classical musical training and becoming the talented guitarist he is today. Later, he would set his steps in the uncharted waters of the world of music production, composing memorable tunes and growing his affinity for music even more, while developing his own style and brand he succeeded in getting the attention of local and international radios, DJ’s & Labels, ranking in the top 100 tracks. The deep sound behind his story reflects his mindset of unexpected creativity that breaks the boundaries of musical genres and cultural barriers, showing his real sense of melody that sticks long in the mind, for years he works on perfecting the art of producing and mixing, strongly embracing his passion for music, art and culture. He follows a deep agenda that spans the realm of art to technological vanguard. As a performing artist, he is constantly pushing conceptual frontiers, moving things forward, welcoming ideas and experiences that define his presence.",
    links: {
      instagram: "https://www.instagram.com/sinanabdelmoati/",
      soundcloud: "https://soundcloud.com/sinanabdelmoati",
      spotify:
        "https://open.spotify.com/artist/3TnJpqkm8DynoHNOZYn5yl?si=UuVhPgxkRi2jibx1u8ABfg&dl_branch=1",
      youtube: "https://www.youtube.com/user/Sinan7893",
    },
    city: "haifa",
  },
  rimon: {
    artistName: "Rimon Haddad",
    angle: 208.5,
    artistDescription:
      "Jazz and Electronic Musician, Producer, Bass player, Songwriter and Composer. Born and live in Haifa/Palestine. Studied jazz and contemporary music. former bassist of Shatea Band (1989-1995). Produced and composed music for theater and released one album ‘everything changed’ (2012). Performed and toured Palestine and worldwide as Bass player with various productions and bands. The passion for experimental and electronic music and sounds since childhood and yet the urge to explore the inside world of his musical heart using all his knowledge, techniques and philosophy led him to act solo and setup his Eurorack Modular Synthesizer which stole his heart and time and started to produce and perform live electronic music since 2018.",
    links: {
      instagram: "https://www.instagram.com/rimonbass/",
      soundcloud: "https://soundcloud.com/raymondhaddad",
      bandcamp: "https://raymondhaddad.bandcamp.com/",
      youtube: "https://www.youtube.com/channel/UC41w1Je9jCaD779-Q__fuNw",
    },
    city: "haifa",
  },
  rasha: {
    artistName: "Rasha Nahhas",
    angle: 216.6,
    artistDescription:
      "Palestinian singer-songwriter and instrumentalist Rasha Nahas was born and raised in Haifa, and currently lives and works in Berlin. A classical guitarist since the age of 10, Rasha has long been crafting a sound that moves seamlessly between the resonances of early rock ‘n’ roll and the reckless echoes of free jazz, complemented by her distinctive approach to songwriting and performance. The unmistakable sonic landscapes and unique artistic identity that she has constructed were shaped in no small part by the underground scene of her hometown, Haifa; giving rise to a vivid mélange of electric guitars, classical technique, and fierce yet intimate storytelling weaved with bold, unabashed poetry and howling violins.",
    links: {
      instagram: "https://www.instagram.com/rasha_nahas/",
      soundcloud: "https://soundcloud.com/rashalpha",
      spotify:
        "https://open.spotify.com/artist/6kA597KeEKoghJHvQ92RYO?si=tJwgjJrvRe-JuR1-mybkDQ&dl_branch=1",
      youtube: "https://www.youtube.com/channel/UC4qpes5v6QKP2IOMTQ-oygQ",
    },
    city: "haifa",
  },
  orabi: {
    artistName: "Orabi",
    angle: 229,
    artistDescription: "",
    links: {
      soundcloud: "https://soundcloud.com/hamody-orabe-huseen",
    },
    city: "haifa",
  },
  jazzar: {
    artistName: "Jazar Crew",
    angle: 237,
    artistDescription:
      "Jazar Crew is a DIY music and art collective that aims to provide a safe space dancefloor for the Palestinian youth. With their revolutionary parties around the region, they built bridges between the Palestinian underground scenes in Haifa, Ramallah and beyond. They have performed in Galilee-Ramallah-Jerusalem-Yaffa & Europe. Founded by a group of young Palestinians that aimed to create a Palestinian Arabic underground scene on this land through music, arts and educational content and activities. The DJ duo made up of Ayed Fadel and Rojeh Khleif is trying to breathe new life into the regional music scene by organizing parties where young Palestinians can momentarily forget the Israel-Palestine conflict.",
    links: {
      instagram: " https://www.instagram.com/jazar.crew/",
      soundcloud: "https://soundcloud.com/jzrc",
    },
    city: "haifa",
  },
  dirar: {
    artistName: "Dirar Kalash",
    angle: 246,
    artistDescription:
      "Dirar works in the spaces between silence, lack of movement, and noise. He employs various instruments and techniques to make music that defies preexisting notions and hierarchical binaries of East and West, Old and New. his starting points are sound as both a medium and as a material, and his tools span the spectrum from improvisation to explore how sounds interact with one another and how and why these sounds are used. His work als relies on the intersections between music and sound on the one hand and other areas of research on the other, like mathematics, architecture, as well as his own visual arts explorations.",
    links: {
      instagram: "https://www.instagram.com/radio_nard/",
      website: "https://www.dirarkalash.info/albums.html",
      youtube: "https://www.youtube.com/channel/UCnUJLGbCEPZ8kaWJaRW1MOQ",
      soundcloud: "https://soundcloud.com/dirarkalash",
    },
    city: "haifa",
  },
  bruno: {
    artistName: "Bruno cruz",
    angle: 257,
    artistDescription:
      "Bruno is an electronic DJ/Producer living in Haifa City in the North of Palestine. He started his career in music in 1998 and has become one of the most prominent names in the Palestinian underground scene. Bruno is the co-founder of Ministry of Dub-key and the official DJ of DAM, and he's been playing his own music infused with his unique style all over the world in festivals such as Fusion Festival in Germany, Glastonbury in the UK and many more.",
    links: {
      soundcloud: "https://soundcloud.com/bruno-cruz",
      instagram: "https://www.instagram.com/djbrunocruz/",
      spotify: "https://open.spotify.com/artist/0yIJhvdaYRB4dOJskdpqxW",
      youtube: "https://www.youtube.com/channel/UCo5XBwqBWs741Ck-qunwspg",
    },
    city: "haifa",
  },
};

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"circle-container"},[_c('div',{staticClass:"circle"},[_vm._l((_vm.artists),function(artist,id){return _c('Artist',{key:id,attrs:{"start-angle":artist.angle,"artist-name":artist.artistName,"is-selected":id === _vm.selectedArtist,"progress":_vm.progress,"set-progress":_vm.setProgress},nativeOn:{"click":function($event){return _vm.setSelectedArtist(id)}}})}),_c('transition',{attrs:{"name":"fade"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.selectedCity),expression:"!selectedCity"}],staticClass:"cities"},[_c('img',{staticClass:"map ramallah-small small",attrs:{"src":require("@/assets/cities/ramallah-01.png")},on:{"click":function($event){_vm.selectedCity = 'ramallah'}}}),_c('img',{staticClass:"map jerusalem-small small",attrs:{"src":require("@/assets/cities/jerusalem-01.png")},on:{"click":function($event){_vm.selectedCity = 'jerusalem'}}}),_c('img',{staticClass:"map bethlehem-small small",attrs:{"src":require("@/assets/cities/bethlehem-01.png")},on:{"click":function($event){_vm.selectedCity = 'bethlehem'}}}),_c('img',{staticClass:"map haifa-small small",attrs:{"src":require("@/assets/cities/haifa-01.png")},on:{"click":function($event){_vm.selectedCity = 'haifa'}}})])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.selectedCity === 'ramallah'),expression:"selectedCity === 'ramallah'"}],staticClass:"link",on:{"click":function($event){_vm.isRadioModalOpen = true}}}),_c('img',{staticClass:"map map-big ramallah-big",class:{
        'ramallah-small map-small': _vm.selectedCity !== 'ramallah',
      },attrs:{"src":require("@/assets/cities/ramallah-zoom-01.png")}}),_c('img',{staticClass:"map map-big bethlehem-big",class:{
        'bethlehem-small map-small': _vm.selectedCity !== 'bethlehem',
      },attrs:{"src":require("@/assets/cities/bethlehem-zoom-01.png")}}),_c('img',{staticClass:"map map-big haifa-big",class:{
        'haifa-small map-small': _vm.selectedCity !== 'haifa',
      },attrs:{"src":require("@/assets/cities/haifa-zoom-01.png")}}),_c('img',{staticClass:"map map-big jerusalem-big",class:{
        'jerusalem-small map-small': _vm.selectedCity !== 'jerusalem',
      },attrs:{"src":require("@/assets/cities/jerusalem-zoom-01.png")}}),_c('div',{staticClass:"map-model-container"},[(
          _vm.selectedArtistData && _vm.selectedCity && _vm.selectedCity !== 'jerusalem'
        )?_c('model-viewer',{key:(_vm.publicPath + "models/" + _vm.selectedCity + ".gltf"),staticClass:"map-model",attrs:{"src":(_vm.publicPath + "models/" + _vm.selectedCity + ".gltf"),"camera-controls":""},on:{"mousedown":_vm.captureOn,"mouseup":_vm.captureOff,"mousemove":_vm.mo}}):_vm._e()],1),_c('posters'),(_vm.selectedCity === 'ramallah' || _vm.selectedCity === 'haifa')?_c('Modal',{attrs:{"modal-class":"modal modal-xl"},on:{"close":function($event){_vm.isRadioModalOpen = false}},model:{value:(_vm.isRadioModalOpen),callback:function ($$v) {_vm.isRadioModalOpen=$$v},expression:"isRadioModalOpen"}},[(_vm.selectedCity === 'ramallah')?_c('iframe',{staticClass:"model-container",attrs:{"title":"Al-Radio","frameborder":"0","allowfullscreen":"","mozallowfullscreen":"true","webkitallowfullscreen":"true","allow":"fullscreen; autoplay; vr","xr-spatial-tracking":"","execution-while-out-of-viewport":"","execution-while-not-rendered":"","web-share":"","src":"https://sketchfab.com/models/7f69cc3534bb4dd486aed89aa09df6f4/embed"}}):(_vm.selectedCity === 'haifa')?_c('iframe',{staticClass:"model-container",attrs:{"title":"Kabareet","frameborder":"0","allowfullscreen":"","mozallowfullscreen":"true","webkitallowfullscreen":"true","allow":"fullscreen; autoplay; vr","xr-spatial-tracking":"","execution-while-out-of-viewport":"","execution-while-not-rendered":"","web-share":"","src":"https://sketchfab.com/models/c783d5c9c83d47589e0e0aee707eeddf/embed"}}):_vm._e()]):_vm._e()],2)])}
var staticRenderFns = []

export { render, staticRenderFns }
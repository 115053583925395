<template>
  <div class="gallery-view" id="gallery">
    <router-link to="/" class="back" title="Back to artists">
      &times;
    </router-link>

    <div v-masonry="'gallery'" transition-duration="0.3s" item-selector=".item">
      <a
        v-for="i in images"
        :key="i"
        :href="links[i]"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          v-masonry-tile
          class="item"
          :src="`${publicPath}gallery/${i}-01.png`"
          @load="debouncedOrder"
        />
      </a>
    </div>
  </div>
</template>

<script>
import links from "./links";
export default {
  name: "GalleryView",
  computed: {
    images() {
      const arr = [];
      for (let index = 1; index <= 28; index++) {
        arr.push(index);
      }
      return arr;
    },
  },
  data() {
    return {
      links: links,
      debounce: null,
    };
  },
  methods: {
    debouncedOrder() {
      if (this.debounce) {
        window.clearTimeout(this.debounce);
      }
      this.debounce = setTimeout(() => {
        this.$redrawVueMasonry("gallery");
      }, 200);
    },
  },
};
</script>

<style scoped lang="scss">
.gallery-view {
  padding: 50px;
}
.item {
  filter: grayscale(100%);
  transition: 0.3s;
  margin-bottom: 10px;
  margin-right: 10px;

  &:hover {
    filter: grayscale(0%);
  }
}
.back {
  position: absolute;
  top: 0px;
  right: 30px;
  font-size: 70px;
  color: #fff;
  font-family: sans-serif;
  font-weight: 100;
  text-decoration: none;
  opacity: 0.3;
  transition: 0.3s;
  z-index: 9;

  &:hover {
    opacity: 1;
  }
}
</style>

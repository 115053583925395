<template>
  <div class="circle-container">
    <div class="circle">
      <Artist
        v-for="(artist, id) of artists"
        :key="id"
        :start-angle="artist.angle"
        :artist-name="artist.artistName"
        :is-selected="id === selectedArtist"
        :progress="progress"
        :set-progress="setProgress"
        @click.native="setSelectedArtist(id)"
      />
      <transition name="fade">
        <div class="cities" v-show="!selectedCity">
          <img
            src="@/assets/cities/ramallah-01.png"
            class="map ramallah-small small"
            @click="selectedCity = 'ramallah'"
          />
          <img
            src="@/assets/cities/jerusalem-01.png"
            class="map jerusalem-small small"
            @click="selectedCity = 'jerusalem'"
          />
          <img
            src="@/assets/cities/bethlehem-01.png"
            class="map bethlehem-small small"
            @click="selectedCity = 'bethlehem'"
          />
          <img
            src="@/assets/cities/haifa-01.png"
            class="map haifa-small small"
            @click="selectedCity = 'haifa'"
          />
        </div>
      </transition>
      <div
        class="link"
        @click="isRadioModalOpen = true"
        v-show="selectedCity === 'ramallah'"
      ></div>
      <img
        src="@/assets/cities/ramallah-zoom-01.png"
        class="map map-big ramallah-big"
        :class="{
          'ramallah-small map-small': selectedCity !== 'ramallah',
        }"
      />
      <img
        src="@/assets/cities/bethlehem-zoom-01.png"
        class="map map-big bethlehem-big"
        :class="{
          'bethlehem-small map-small': selectedCity !== 'bethlehem',
        }"
      />
      <img
        src="@/assets/cities/haifa-zoom-01.png"
        class="map map-big haifa-big"
        :class="{
          'haifa-small map-small': selectedCity !== 'haifa',
        }"
      />
      <img
        src="@/assets/cities/jerusalem-zoom-01.png"
        class="map map-big jerusalem-big"
        :class="{
          'jerusalem-small map-small': selectedCity !== 'jerusalem',
        }"
      />

      <div class="map-model-container">
        <model-viewer
          v-if="
            selectedArtistData && selectedCity && selectedCity !== 'jerusalem'
          "
          class="map-model"
          :key="`${publicPath}models/${selectedCity}.gltf`"
          :src="`${publicPath}models/${selectedCity}.gltf`"
          @mousedown="captureOn"
          @mouseup="captureOff"
          @mousemove="mo"
          camera-controls
        />
      </div>

      <posters />

      <Modal
        v-model="isRadioModalOpen"
        @close="isRadioModalOpen = false"
        v-if="selectedCity === 'ramallah' || selectedCity === 'haifa'"
        modal-class="modal modal-xl"
      >
        <iframe
          v-if="selectedCity === 'ramallah'"
          title="Al-Radio"
          class="model-container"
          frameborder="0"
          allowfullscreen
          mozallowfullscreen="true"
          webkitallowfullscreen="true"
          allow="fullscreen; autoplay; vr"
          xr-spatial-tracking
          execution-while-out-of-viewport
          execution-while-not-rendered
          web-share
          src="https://sketchfab.com/models/7f69cc3534bb4dd486aed89aa09df6f4/embed"
        />
        <iframe
          v-else-if="selectedCity === 'haifa'"
          title="Kabareet"
          frameborder="0"
          allowfullscreen
          mozallowfullscreen="true"
          webkitallowfullscreen="true"
          allow="fullscreen; autoplay; vr"
          xr-spatial-tracking
          execution-while-out-of-viewport
          execution-while-not-rendered
          web-share
          class="model-container"
          src="https://sketchfab.com/models/c783d5c9c83d47589e0e0aee707eeddf/embed"
        ></iframe>
      </Modal>
    </div>
  </div>
</template>

<script>
import Artist from "./Artist.vue";
import artists from "@/constants/artists";
import Posters from "./Posters.vue";

export default {
  name: "CircleComponent",
  components: {
    Artist,
    Posters,
  },
  props: {
    selectedArtist: {
      type: String,
    },
    setSelectedArtist: {
      type: Function,
    },
    progress: {
      type: Number,
    },
    setProgress: {
      type: Function,
    },
  },
  watch: {
    selectedArtist() {
      this.selectedArtistData = artists[this.selectedArtist] ?? {};
      this.selectedCity = artists[this.selectedArtist]?.city ?? "";
    },
  },
  data: () => ({
    artists: artists,
    selectedArtistData: {},
    isRadioModalOpen: false,
    shouldOpenModal: false,
    selectedCity: "",
  }),
  methods: {
    mo() {
      this.shouldOpenModal = false;
    },
    captureOn() {
      this.shouldOpenModal = true;
    },
    captureOff() {
      const isValidCity =
        this.selectedCity === "ramallah" || this.selectedCity === "haifa";
      if (isValidCity && this.shouldOpenModal) {
        this.isRadioModalOpen = true;
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "~@/constants/_variables.scss";

.circle {
  width: ($circle-radius-big * 2) + 0px;
  height: ($circle-radius-big * 2) + 0px;
  display: block;
  border: 3px solid #d72228;
  border-radius: 50%;
  margin: auto;
  position: relative;
}

.map {
  transform: translate(-50%, -50%);
  position: absolute;
  z-index: 1;

  &.small {
    z-index: 2;
    cursor: pointer;
    opacity: 0.8;

    transition: 0.3s opacity;

    &:hover {
      opacity: 1;
    }
  }
}

.map-big {
  transition: 0.5s, opacity 0.1s;
  width: 100%;
  left: 50%;
  top: 50%;
  opacity: 1;

  &.map-small {
    transition: 0.5s, opacity 0.5s;
    opacity: 0;
  }
}

.ramallah-small {
  width: 30%;
  left: 54%;
  top: 55%;
}

.jerusalem-small {
  width: 13%;
  left: 45%;
  top: 79%;
}

.bethlehem-small {
  width: 24%;
  left: 62%;
  top: 91%;
}

.haifa-small {
  width: 20%;
  left: 52.4%;
  top: 8.6%;
}

.link {
  position: absolute;
  width: 50px;
  height: 50px;
  top: 49.9%;
  right: 21%;
  z-index: 2;
  border: 2px solid #ec2d33;
  border-radius: 50%;
  cursor: pointer;
  transition: 0.3s;
  opacity: 0;
  background: rgba(#ec2d33, 0.2);

  &:hover {
    opacity: 1;
  }
}

.model-container {
  width: 100%;
  height: calc(80vh - 44px);
  z-index: 5;
}

.map-model,
.map-model-container {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  z-index: 2;
  overflow: hidden;
}

@media screen and (max-width: $small-media-width + px) {
  .circle {
    width: ($circle-radius-small * 2) + 0px;
    height: ($circle-radius-small * 2) + 0px;
  }
}
</style>

<style lang="scss">
.modal-xl {
  width: 80vw;
  height: 80vh;
  max-width: none;
  position: relative;
  padding: 0;
  background: transparent;

  .vm-titlebar {
    border: none;
    padding-right: 0;
  }
  .vm-content {
    padding: 0;
  }
}
</style>

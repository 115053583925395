<template>
  <div class="lines-container" :style="cssProps">
    <div
      class="line"
      :class="{
        long: index === 0,
        active: isSelected && progress >= index,
        reverse: startAngle > 90 && startAngle < 270,
      }"
      v-for="(el, index) in elements"
      :key="index"
      :style="el"
      @click="setProgress(index)"
    >
      <div class="dot" v-if="index === 0"></div>
      <div class="left-dash" v-if="index !== 0"></div>
      <div class="right-dash" v-if="index !== 0"></div>
      <div class="name" v-if="index === 0">{{ artistName }}</div>
      <div class="line-inside"></div>
    </div>
  </div>
</template>

<script>
import variables from "@/constants/_variables.scss";

export default {
  name: "Artist",
  props: {
    startAngle: {
      type: Number,
    },
    artistName: {
      type: String,
    },
    isSelected: {
      type: Boolean,
    },
    progress: {
      type: Number,
    },
    setProgress: {
      type: Function,
    },
  },
  computed: {
    cssProps() {
      return {
        ...this.createLine(this.startAngle, true),
        "--long-line-right-width-offset": this.artistName.length * 5 + "px",
      };
    },
    elements() {
      const elements = [];
      const isInverse = this.startAngle > 90 && this.startAngle < 270;
      for (
        let angle = 0;
        isInverse ? angle > -7 : angle < 7;
        angle += isInverse ? -2 : 2
      ) {
        elements.push(this.createLine(angle));
        // elements.push({})
      }
      return elements;
    },
    circleRadius() {
      const isSmallScreen = window.matchMedia(
        `(max-width: ${variables.smallMediaWidth}px)`
      );
      return isSmallScreen.matches
        ? variables.circleRadiusSmall
        : variables.circleRadiusBig;
    },
  },
  methods: {
    createLine(angle, isContainer = false) {
      const rad = angle * (Math.PI / 180);
      const radius = this.circleRadius;
      const center = radius - 1;
      const cssObject = {
        transform: `translateX(-50%) rotateZ(${rad}rad)`,
      };

      if (isContainer) {
        cssObject.left = Math.cos(rad) * radius + center + "px";
        cssObject.top = Math.sin(rad) * radius + center + "px";
      } else {
        cssObject.left = 0;
        cssObject.top = Math.sin(rad) * radius + "px";
      }
      return cssObject;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "~@/constants/_variables.scss";

$bg-color: #fff;

.lines-container {
  z-index: 3;
  position: absolute;

  &:hover {
    .long,
    .name {
      opacity: 1;
    }
  }
}

.line {
  --long-line-right-width: 106px;
  --long-line-left-width: 90px;

  --short-line-right-width: 80px;
  --short-line-left-width: 30px;

  @media screen and (max-width: $small-media-width + px) {
    --long-line-right-width: 53px;
    --long-line-left-width: 70px;

    --short-line-right-width: 50px;
    --short-line-left-width: 20px;
  }

  height: 0;
  width: 0;
  background: $bg-color;
  position: absolute;
  opacity: 0.1;
  transition: opacity 0.3s;
  cursor: pointer;
  z-index: 3;

  &.long {
    opacity: 0.4;
  }

  &:hover,
  &.active {
    opacity: 1;

    .name {
      opacity: 1;
    }
  }

  &:before,
  &:after {
    width: calc(
      var(--short-line-right-width) + var(--long-line-right-width-offset) - 20px
    );
    height: 1px;
    content: "";
    background: $bg-color;
    display: block;
  }

  &:before {
    width: var(--short-line-left-width);
    transform: translate(calc(0px - var(--short-line-left-width)), 1px);
  }

  .name {
    position: absolute;
    top: -18px;
    right: calc(
      0px - (var(--long-line-right-width) + var(--long-line-right-width-offset))
    );
    text-align: right;
    font-weight: bold;
    color: $bg-color;
    text-transform: uppercase;
    white-space: nowrap;
    opacity: 0;
  }

  &.reverse .name {
    transform: rotateZ(180deg);
    top: 2px;
  }

  .dot {
    width: 7px;
    height: 7px;
    position: absolute;
    background: $bg-color;
    border-radius: 50%;
    top: -2px;
    right: calc(
      0px -
        (
          var(--long-line-right-width) + var(--long-line-right-width-offset) +
            7px
        )
    );
  }

  .right-dash,
  .left-dash {
    width: 1px;
    height: 5px;
    position: absolute;
    background: $bg-color;
    top: -1px;
    right: calc(
      0px - var(--short-line-right-width) - var(--long-line-right-width-offset) +
        20px
    );
  }

  .left-dash {
    right: auto;
    left: calc(0px - var(--short-line-left-width));
  }

  .line-inside {
    width: calc(
      var(--short-line-right-width) + var(--short-line-left-width) +
        var(--long-line-right-width-offset) - 20px
    );
    height: 7px;
    top: -2px;
    position: absolute;
    left: calc(0px - var(--short-line-left-width));
  }

  &.long {
    .line-inside {
      width: calc(
        var(--long-line-left-width) + var(--long-line-right-width) +
          var(--long-line-right-width-offset)
      );
      height: 7px;
      top: -2px;
      position: absolute;
      left: calc(0px - var(--long-line-left-width));
    }

    &:before,
    &:after {
      width: calc(
        var(--long-line-right-width) + var(--long-line-right-width-offset)
      );
    }

    &:before {
      width: var(--long-line-left-width);
      transform: translate(calc(0px - var(--long-line-left-width)), 1px);
    }
  }
}
</style>

<template>
  <div class="posters-container">
    <div class="posters">
      <!-- <div class="horizontal"></div> -->
      <img
        class="poster"
        :class="[{ active: hoveredPoster === i }, 'p' + i]"
        :src="`${publicPath}posters/${i}-01.png`"
        v-for="i in images"
        :key="'img' + i"
      />
      <div
        v-for="i in images"
        :key="'poster' + i"
        class="poster-container"
        @mouseenter="hoveredPoster = i"
        @mouseleave="hoveredPoster = -1"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Posters",
  computed: {
    images() {
      const arr = [];
      for (let index = 1; index <= 76; index++) {
        arr.push(index);
      }
      return arr;
    },
  },
  data() {
    return {
      hoveredPoster: -1,
    };
  },
};
</script>

<style scoped lang="scss">
@import "~@/constants/_variables.scss";

.posters-container {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: url("~@/assets/connections.png") -2px -6px no-repeat;
  background-size: calc(100% + 2px);
}

.posters {
  position: absolute;
  top: 0;
  left: calc(50% - 1px);
  z-index: 8;
  width: 2px;
}
.poster-container {
  width: 2px;
  height: 5.2px;
  margin-bottom: 3px;
  background: #fff;
  opacity: 0.4;
  z-index: 3;

  &:before,
  &:after {
    content: "";
    width: 5px;
    height: 100%;
    display: block;
    position: relative;
  }

  &:after {
    top: -6px;
    left: -3px;
  }

  &:hover {
    opacity: 1;
  }

  @media screen and (max-width: $small-media-width + px) {
    height: 4.3px;

    &:after {
      top: -4.3px;
    }
  }
}

.poster {
  display: none;
  position: absolute;
  width: 900px;
  height: 900px;
  transform: translate(calc(-50% - -1px), -142px);
  z-index: -1;

  @media screen and (max-width: $small-media-width + px) {
    width: 800px;
    height: 800px;
    transform: translate(calc(-50% - -1px), -127px);
  }

  &.p1 {
    width: auto;
    height: auto;
    left: 67px;
    top: 81px;
  }

  &.active {
    display: block;
  }
}

.horizontal {
  width: ($circle-radius-big * 2) + 0px;
  height: 0px;
  border-top: 2px solid white;
  left: (-$circle-radius-big) + 0px;
  position: relative;
  top: $circle-radius-big + 0px;
  opacity: 0.6;
  border-top-style: dashed;

  @media screen and (max-width: $small-media-width + px) {
    width: ($circle-radius-small * 2) + 0px;
    left: (-$circle-radius-small) + 0px;
    top: ($circle-radius-small) + 0px;
  }
}
</style>

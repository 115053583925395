<template>
  <div id="artist-view">
    <div
      v-if="!selectedArtist"
      @click="togglePlayPause"
      class="playpausebutton"
    >
      <span v-if="!isPlaying"> &#128263; </span>
      <span v-else> &#128266; </span>
    </div>
    <div class="second-line-container">
      <router-link to="/gallery" class="second-line" />
      <div class="second-line-inner"></div>
    </div>
    <CircleComponent
      :selected-artist="selectedArtist"
      :set-selected-artist="setSelectedArtist"
      :progress="progress"
      :set-progress="setProgress"
    ></CircleComponent>
    <ArtistInfo
      :selected-artist="selectedArtist"
      :progress="progress"
      :setSlectedArtist="setSelectedArtist"
    />
    <audio
      ref="audioElement"
      @playing="updatePaused"
      @pause="updatePaused"
      :src="
        selectedArtistInfo
          ? publicPath + 'music/' + selectedArtist + '.mp3'
          : publicPath + 'music/website.mp3'
      "
      autoplay
    />
  </div>
</template>

<script>
import CircleComponent from "./components/CircleComponent.vue";
import ArtistInfo from "./components/ArtistInfo.vue";
import artists from "@/constants/artists";

export default {
  name: "ArtistView",
  components: {
    CircleComponent,
    ArtistInfo,
  },
  computed: {
    selectedArtistInfo() {
      return artists[this.selectedArtist];
    },
  },
  mounted() {
    const audioElement = this.$refs.audioElement;
    audioElement.addEventListener("timeupdate", this.onUpdateTime);
  },
  data: () => ({
    selectedArtist: "",
    progress: 0,
    publicPath: process.env.BASE_URL,
    isPlaying: false,
  }),
  methods: {
    setSelectedArtist(artist) {
      this.selectedArtist = artist;
      if (!artist) {
        this.$refs.audioElement.pause();
      }
    },
    onUpdateTime(e) {
      const duration = e.target.duration;
      const currentTime = e.target.currentTime;

      const percent = (4 * currentTime) / duration;
      this.progress = Math.floor(percent);
    },
    setProgress(progress) {
      this.progress = progress;
      setTimeout(() => {
        const audioElement = this.$refs.audioElement;
        const loadFunction = () => {
          const duration = audioElement.duration;
          const durationToSeek = (duration / 4) * progress;
          audioElement.currentTime = durationToSeek;
          audioElement.play();
        };
        if (audioElement.duration) {
          loadFunction();
        } else {
          audioElement.addEventListener("loadedmetadata", loadFunction, {
            once: true,
          });
        }
      });
    },
    togglePlayPause() {
      !this.$refs.audioElement?.paused
        ? this.$refs.audioElement.pause()
        : this.$refs.audioElement.play();
    },
    updatePaused() {
      this.isPlaying = !this.$refs.audioElement?.paused;
    },
  },
};
</script>

<style lang="scss">
@import "~@/constants/_variables.scss";

#artist-view {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: $app-height-big;
  @media screen and (max-width: $small-media-width + px) {
    min-height: $app-height-small;
  }
}

model-viewer {
  --progress-mask: transparent;
  --poster-color: transparent;
}

.second-line-container {
  width: 1170px;
  height: 1170px;
  position: absolute;
  overflow: hidden;
  z-index: 0;
  @media screen and (max-width: $small-media-width + px) {
    width: 935px;
    height: 935px;
  }
}

.second-line {
  width: 1160px;
  height: 1160px;
  left: 5px;
  border-radius: 50%;
  opacity: 0.3;
  color: white;
  position: absolute;
  transition: 0.3s opacity;
  background: url("~@/assets/line-01.png") center center no-repeat;
  background-size: contain;

  @media screen and (max-width: $small-media-width + px) {
    width: 930px;
    height: 930px;
    left: 2px;
  }

  &:hover {
    opacity: 1;
  }
}

.second-line-inner {
  width: 1125px;
  height: 1125px;
  border-radius: 50%;
  position: absolute;
  top: 17px;
  left: 17px;

  @media screen and (max-width: $small-media-width + px) {
    width: 900px;
    height: 900px;
  }
}

.playpausebutton {
  z-index: 99999999999;
  position: fixed;
  top: 30px;
  left: 30px;
  cursor: pointer;
  font-size: 30px;
}
</style>

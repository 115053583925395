<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>

<style lang="scss">
@import "~@/constants/_variables.scss";

#app {
  color: #b3b3b3;
  background: #2b2b2b url("~@/assets/2-01.png") center center no-repeat;
  background-size: contain;
  min-height: $app-height-big;
    @media screen and (max-width: $small-media-width + px) {
  min-height: $app-height-small;
  }
}
</style>
